export const ServiceUrl = {
  V2_LOGIN: {
    CHECK_USERNAME: "v2/login/check-username",
    CAPTCHA: "v1/captcha",
    VERIFY_CAPTCHA: "v2/login/verify-captcha",
    UPDATE_PASSWORD: "v2/login/update-pass",
  },

  LOGIN_V2: {
    CHECK: "/v2/admin/check",
    CHECK_USERNAME: "/v1/admin/login",
  },
  PRODUCTS: {
    BRAND_CREATE: "v1/admin/master/brand/create",
    BRAND_LIST: "v1/admin/master/brand/list",
    BRAND_DELETE: "v1/admin/master/brand/delete",
    BRAND_UPDATE: "v1/admin/master/brand/update",

    GROUP_CREATE: "v1/admin/master/group/create",
    GROUP_LIST: "v1/admin/master/group/list",
    GROUP_DELETE: "v1/admin/master/group/delete",
    GROUP_UPDATE: "v1/admin/master/group/update",

    ITEM_CREATE: "v1/admin/master/item/create",
    ITEM_QUICK_CREATE: "v1/admin/master/item/quick-create",
    ITEM_LIST: "v1/admin/master/item/list",
    ITEM_DELETE: "v1/admin/master/item/delete",
    ITEM_UPDATE: "v1/admin/master/item/update",

    CLIENT_CREATE: "v1/admin/master/client/create",
    CLIENT_LIST: "v1/admin/master/client/list",
    CLIENT_DELETE: "v1/admin/master/client/delete",
    CLIENT_UPDATE: "v1/admin/master/client/update",

    TAX_CREATE: "v1/admin/master/tax/create",
    TAX_LIST: "v1/admin/master/tax/list",
    TAX_DELETE: "v1/admin/master/tax/delete",
    TAX_UPDATE: "v1/admin/master/tax/update",

    TABLE_CREATE: "v1/admin/master/table/create",
    TABLE_LIST: "v1/admin/master/table/list",
    TABLE_DELETE: "v1/admin/master/table/delete",
    TABLE_UPDATE: "v1/admin/master/table/update",

    SPERSON_CREATE: "v1/admin/master/sperson/create",
    SPERSON_LIST: "v1/admin/master/sperson/list",
    SPERSON_DELETE: "v1/admin/master/sperson/delete",
    SPERSON_UPDATE: "v1/admin/master/sperson/update",

    SALES_MODE_CREATE: "v1/admin/master/salesmode/create",
    SALES_MODE_LIST: "v1/admin/master/salesmode/list",
    SALES_MODE_DELETE: "v1/admin/master/salesmode/delete",
    SALES_MODE_UPDATE: "v1/admin/master/salesmode/update",
  },
  PURCHASE: {
    PURCHASE_NO: "v1/admin/purchase/purchase-no",
    PURCHASE_CREATE: "v1/admin/purchase/create",
    PURCHASE_LIST: "v1/admin/purchase/list",
    PURCHASE_DELETE: "v1/admin/purchase/delete",
  },
  SALES: {
    SALES_NO: "v1/admin/sales/sales-no",
    SALES_CREATE: "v1/admin/sales/create",
    SALES_LIST: "v1/admin/sales/list",
    SALES_DELETE: "v1/admin/sales/delete",
  },
  VOUCHERS: {
    VOUCHER_NO: "v1/admin/voucher/voucher-no",
    VOUCHER_SAVE: "v1/admin/voucher/create",
    VOUCHER_LIST: "v1/admin/voucher/list",
    VOUCHER_DELETE: "v1/admin/voucher/delete",
  },
  REPORTS: {
    VOUCHERS: "v1/admin/report/vouchers",
  },
  ADMISSION: {
    SETTINGS_MENU: "v1/admin/settings/menu-list",
    SETTINGS: "v1/settings",
    LIST_VARIABLE_SETTINGS: "v1/admin/settings/list-settings",
    UPDATE_VARIABLE_SETTINGS: "v1/admin/settings/update-settings",
    ADD_VARIABLE_SETTINGS: "v1/admin/settings/add-settings",

    LIST_SMS_TEMPLATES: "v1/admin/smstemplate/list-templates",
    UPDATE_SMS_TEMPLATES: "v1/admin/smstemplate/update-template",
    ADD_SMS_TEMPLATES: "v1/admin/smstemplate/add-template",
    DELETE_SMS_TEMPLATES: "v1/admin/smstemplate/delete-template",

    LIST_MAIL_TEMPLATES: "v1/admin/mailtemplate/list-templates",
    UPDATE_MAIL_TEMPLATES: "v1/admin/mailtemplate/update-template",
    ADD_MAIL_TEMPLATES: "v1/admin/mailtemplate/add-template",
    DELETE_MAIL_TEMPLATES: "v1/admin/mailtemplate/delete-template",

    USER_PROFILE: "v1/admin/users/my-profile",
    USER_CHANGE_PASSWORD: "v1/admin/users/change-password",

    NEW_USER_LOGIN: "v1/admin/users/save-user",
    LIST_USERS_LOGIN: "v1/admin/users/list-users",
    USER_STATUS_CHANGE: "v1/admin/users/status-change",
    REMOVE_USER_LOGIN: "v1/admin/users/remove-user",
    UPDATE_USER_LOGIN: "v1/admin/users/update-user",
    PASS_USER_LOGIN: "v1/admin/users/user-password",
  },

  SETTINGS: {
    SAVE_USERROLE: "v1/admin/rbac/userrole/save",
    LIST_USERROLE: "v1/admin/rbac/userrole/list",
    DELETE_USERROLE: "v1/admin/rbac/userrole/delete",
    UPDATE_USERROLE: "v1/admin/rbac/userrole/update",

    SAVE_ROLE_MODULE: "v1/admin/rbac/roleaccess/save-module",
    DELETE_ROLE_MODULE: "v1/admin/rbac/roleaccess/delete-module",
    UPDATE_ROLE_MODULE: "v1/admin/rbac/roleaccess/update-module-access",
    UPDATE_USER_ACCESS: "v1/admin/rbac/roleaccess/update-user-access",
    LIST_ROLE_ACCESS: "v1/admin/rbac/roleaccess/access-list",
    UPDATE_ROLE_ACCESS: "v1/admin/rbac/roleaccess/update-role-access",
    LIST_ROLE_PERMISSIONS: "v1/admin/rbac/roleaccess/list-role-permissions",

    LIST_COMMUNITY: "v1/set/community",
    LIST_RELIGIONS: "v1/set/religions",

    LIST_USER_LOGS: "v1/admin/logs/list-all",
  },

  FIELD_SETTINGS: {
    NEW: "v1/field_settings/new",
    LIST: "v1/field_settings/all",
    REMOVE: "v1/field_settings/delete",
    UPDATE: "v1/field_settings/update",
  },
  SMS: {
    CHECK_BALANCE: "v1/admin/sms/balance",
  },
};
