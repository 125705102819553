import React, { useEffect, useState } from "react";

import { Card, Col, Form, Row, Button } from "react-bootstrap";
import { VENDOR_LOGO } from "../../utils/data";

import { Spin } from "antd";

import { queryParam } from "../../utils";
import UserNameInput from "./userNameInput";

import OtpVerify from "./otpVerify";
import NewPasswordInput from "./newPasswordInput";
import AOS from "aos";

const ForgotPassword = (props) => {
  const [loader, setLoader] = useState(false);
  const [validated, setValidated] = useState(false);

  const userParams = (field) => {
    let s = queryParam(props.location.search);
    if (s[field]) return s[field];
  };

  useEffect(() => {
    AOS.refresh();
  }, []);

  return (
    <div>
      <div className="container ">
        <Row style={{ marginTop: "6%" }}>
          <Col md={{ span: 4, offset: 4 }}>
            <Spin spinning={loader}>
              <Card data-aos="fade-up">
                <Card.Body>
                  <div className="text-center">
                    <img
                      src={VENDOR_LOGO}
                      className="bg-white rounded-3 mb-3"
                      style={{ width: "120px" }}
                    />
                  </div>
                  <h5 className="fw-bold">Forgot Password</h5>

                  {!userParams("type") && <UserNameInput {...props} />}
                  {userParams("type") == "otp" && <OtpVerify {...props} />}
                  {userParams("type") == "password" && (
                    <NewPasswordInput {...props} />
                  )}
                </Card.Body>
              </Card>
            </Spin>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ForgotPassword;
