import React, { useContext, useEffect, useState } from "react";

import {
  Card,
  Col,
  Form,
  Row,
  Button,
  InputGroup,
  Alert,
} from "react-bootstrap";

import { Spin } from "antd";
import axios from "axios";
import { ServiceUrl } from "../../utils/serviceUrl";
import { toast } from "react-hot-toast";
import { Link } from "react-router-dom";
import { queryParam } from "../../utils";
import PsContext from "../../context";

const OtpVerify = (props) => {
  const context = useContext(PsContext);
  const [loader, setLoader] = useState(false);
  const [validated, setValidated] = useState(false);

  const [username, setUserName] = useState("");
  const [captcha, setCaptcha] = useState([]);

  useEffect(() => {
    loadCaptcha();
  }, []);

  const loadCaptcha = () => {
    /* setLoader(true);
    axios.get(ServiceUrl.V2_LOGIN.CAPTCHA).then((res) => {
      if (res["data"].status == "1") {
        setCaptcha(res["data"]);
      }
      setLoader(false);
    });*/
  };

  const userParams = (field) => {
    let s = queryParam(props.location.search);
    if (s[field]) return s[field];
  };

  const user = (field) => {
    let s = context.decode(userParams("token"));
    return s[field] || "";
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() == false) {
      e.stopPropagation();
      setValidated(true);
      return;
    }
    setLoader(true);
    axios
      .post(ServiceUrl.V2_LOGIN.VERIFY_CAPTCHA, new FormData(form))
      .then((res) => {
        if (res["data"].status == "1") {
          setUserName(res["data"].token);
          /*history.push(
            "/forgot-password?type=password&token=" + res["data"].token
          );*/
        } else {
          toast.error(res["data"].message || "Error");
          loadCaptcha();
        }
        setLoader(false);
      });
  };

  return (
    <div>
      <Spin spinning={loader}>
        <Form
          action=""
          method="post"
          noValidate
          validated={validated}
          onSubmit={handleFormSubmit}
        >
          <Alert variant="info" className="bg-blue-50">
            We are sending OTP to your registered mobile number
          </Alert>

          <Row className="mt-2">
            <Col md={12}>
              <InputGroup>
                <Form.Control
                  type="text"
                  name="username"
                  placeholder="Mobile number or username"
                  className="fw-bold bg-light"
                  value={user("username")}
                  required
                />
                <InputGroup.Text>
                  <Link to="/forgot-password">
                    <i className="fa-regular fa-pen-to-square"></i>
                  </Link>
                </InputGroup.Text>
              </InputGroup>
            </Col>
          </Row>
          <input type="hidden" name="token" value={userParams("token")} />

          <Row className="mt-3">
            <Col md={12}>
              <Form.Control
                type="number"
                name="otp"
                placeholder="Enter OTP"
                className="fw-bold"
                required
              />
              <Form.Control.Feedback type="invalid">
                Enter your OTP
              </Form.Control.Feedback>
            </Col>
          </Row>

          <Row className="mt-4">
            <Col md={12}>
              <Button type="submit" className="w-100">
                Next
              </Button>
            </Col>
          </Row>
        </Form>
      </Spin>
    </div>
  );
};

export default OtpVerify;
