import React, { useContext, useEffect, useState } from "react";
import LoginV2Username from "../loginV2/loginV2Username";
import {
  Card,
  Col,
  Form,
  Row,
  Button,
  InputGroup,
  Alert,
} from "react-bootstrap";
import { VENDOR_LOGO } from "../../utils/data";
import LoaderSubmitButton from "../../utils/LoaderSubmitButton";
import { Spin } from "antd";
import axios from "axios";
import { ServiceUrl } from "../../utils/serviceUrl";
import { toast } from "react-hot-toast";
import { Link, redirect } from "react-router-dom";
import { queryParam } from "../../utils";
import PsContext from "../../context";

const NewPasswordInput = (props) => {
  const context = useContext(PsContext);
  const [loader, setLoader] = useState(false);
  const [validated, setValidated] = useState(false);
  const [passError, setPassError] = useState(false);

  const [username, setUserName] = useState("");
  const [captcha, setCaptcha] = useState([]);

  const [pass, setPass] = useState("");
  const [confirmPass, setConfirmPass] = useState("");

  const [showPass, setShowPass] = useState(false);
  const [showConfirmPass, setShowConfirmPass] = useState(false);

  useEffect(() => {
    loadCaptcha();
  }, []);

  const loadCaptcha = () => {
    /* setLoader(true);
    axios.get(ServiceUrl.V2_LOGIN.CAPTCHA).then((res) => {
      if (res["data"].status == "1") {
        setCaptcha(res["data"]);
      }
      setLoader(false);
    });*/
  };

  const userParams = (field) => {
    let s = queryParam(props.location.search);
    if (s[field]) return s[field];
  };

  const user = (field) => {
    let s = context.decode(userParams("token"));

    return s[field] || "";
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() == false) {
      e.stopPropagation();
      setValidated(true);
      return;
    }

    var strongRegex = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
    );
    if (!strongRegex.test(pass)) {
      setPassError(true);
      toast.error("Set strong password");
      return;
    }

    if (pass.trim() !== confirmPass.trim()) {
      toast.error("Password and Confirm password does not match");
      return;
    }
    setLoader(true);
    axios
      .post(ServiceUrl.V2_LOGIN.UPDATE_PASSWORD, new FormData(form))
      .then((res) => {
        if (res["data"].status == "1") {
          toast.success(res["data"].message || "Success");
          //history.push("/");
        } else {
          toast.error(res["data"].message || "Error");
          loadCaptcha();
        }
        setLoader(false);
      });
  };

  if (user("allow_pass") != "yes") {
    return redirect("/");
  }

  const passBlur = (e) => {
    let value = e.target.value.trim();
    /*let reg = new RegExp(
      "/^(?=.*[a-z])(?=.*[A-Z])(?=.*d)(?=.*[#$@!%&*?])[A-Za-zd#$@!%&*?]{8,30}$/"
    );*/
    var strongRegex = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
    );
    if (strongRegex.test(value)) {
      setPassError(false);
    } else {
      setPassError(true);
    }
  };

  return (
    <div>
      <Spin spinning={loader}>
        <Form
          action=""
          method="post"
          noValidate
          validated={validated}
          onSubmit={handleFormSubmit}
        >
          <input type="hidden" name="token" value={userParams("token")} />

          <Row className="mt-2">
            <Col md={12}>
              <label>Password</label>
              <Form.Control
                type={showPass ? "text" : "password"}
                required
                className="fw-bold"
                placeholder="Password"
                name="password"
                value={pass}
                onChange={(e) => setPass(e.target.value)}
                onKeyUp={passBlur}
              />
              <a
                className="pass_show_hide"
                onClick={(e) => setShowPass(!showPass)}
              >
                {showPass ? "Hide" : "Show"}
              </a>
            </Col>
          </Row>

          <Row className="mt-2">
            <Col md={12}>
              <label>Confirm Password</label>
              <Form.Control
                type={showConfirmPass ? "text" : "password"}
                name="confirm_password"
                required
                className="fw-bold"
                placeholder="Confirm Password"
                value={confirmPass}
                onChange={(e) => setConfirmPass(e.target.value)}
              />
              <a
                className="pass_show_hide"
                onClick={(e) => setShowConfirmPass(!showConfirmPass)}
              >
                {showConfirmPass ? "Hide" : "Show"}
              </a>
            </Col>
          </Row>

          {passError && (
            <Row className="mt-2">
              <Col md={12}>
                <Alert className="bg-red-50" variant="danger">
                  Password must container
                  <ul>
                    <li>Min 1 uppercase letter.</li>
                    <li>Min 1 lowercase letter.</li>
                    <li>Min 1 special character</li>
                    <li>Min 1 number</li>
                    <li>Min 8 characters</li>
                    <li>Max 30 characters</li>
                  </ul>
                </Alert>
              </Col>
            </Row>
          )}

          <Row className="mt-4">
            <Col md={12}>
              <Button type="submit" className="w-100">
                Next
              </Button>
            </Col>
          </Row>
        </Form>
      </Spin>
    </div>
  );
};

export default NewPasswordInput;
