import _LOGO from "../assets/images/kl_logo.png";

import _MALE from "../assets/images/male_large.jpg";
import _FEMALE from "../assets/images/female_large.jpg";
import _SELECT_USER_PHOTO from "../assets/images/select_user_photo.png";

import { baseUrl, localSettingsValues } from ".";

export const VENDOR_LOGO = baseUrl + "/public/app_fav.png";
export const VENDOR_SECONDARY_LOGO = baseUrl + "/public/secondary_logo.png";

export const LOGO = _LOGO;
export const MALE = _MALE;
export const FEMALE = _FEMALE;
export const SELECT_USER_PHOTO = _SELECT_USER_PHOTO;

export const printHeader = "";

export const DEFAULT_PAGE_LIST_SIZE = "50";

export const aosInit = {
  offset: 100,
  duration: 600,
  easing: "ease-in-sine",
  delay: 100,
};

export const BLOOD_GROUPS = [
  "A+",
  "B+",
  "A-",
  "B-",
  "B1+",
  "O+",
  "O-",
  "AB+",
  "AB-",
  "A1+",
  "A1-",
  "A2+",
  "A2-",
  "A1B+",
  "A1B-",
  "A2B+",
  "A2B-",
];

export const COURSE_TYPE_SORT_ORDER = localSettingsValues(
  "academic_course_types",
  "ug"
)?.split(",");

export const TABLE_STYLES = {
  tableCollapse: {
    borderCollapse: "collapse",
    border: "none",
  },
  borderBottom: {
    borderCollapse: "collapse",
    borderBottom: "1px solid black",
  },
  borderTop: {
    borderCollapse: "collapse",
    borderTop: "1px solid black",
  },
  borderExceptLeft: {
    borderCollapse: "collapse",
    borderBottom: "1px solid black",
    borderTop: "1px solid black",
    borderRight: "1px solid black",
    padding: "3px",
  },
  borderExceptRight: {
    borderCollapse: "collapse",
    borderBottom: "1px solid black",
    borderTop: "1px solid black",
    borderLeft: "1px solid black",
    padding: "3px",
  },
  borderAll: {
    borderCollapse: "collapse",
    border: "1px solid black",
    padding: "4px",
  },
  borderAllBold: {
    borderCollapse: "collapse",
    border: "1px solid black",
    padding: "4px",
    fontWeight: "bold",
  },
  borderAllNoPadding: {
    borderCollapse: "collapse",
    border: "1px solid black",
    padding: "0px",
  },
  trHideborderAll: {
    display: "none",
    borderCollapse: "collapse",
    border: "1px solid black",
    padding: "4px",
  },
  borderAllHead: {
    borderCollapse: "collapse",
    border: "1px solid black",
    padding: "4px",
    backgroundColor: "#efefef",
    printColorAdjust: "exact",
  },
  borderTopBottom: {
    borderCollapse: "collapse",
    borderTop: "1px solid black",
    borderBottom: "1px solid black",
    padding: "3px",
  },
  borderLeftRight: {
    borderCollapse: "collapse",
    borderLeft: "1px solid black",
    borderRight: "1px solid black",
    padding: "3px",
  },
  doubleBorderTop: { borderTop: "1px double black" },
  bgSuccessLight: {
    backgroundColor: "#cfffcf",
  },
  none: {
    textDecoration: "none",
  },
  borderTopBottomDashed: {
    borderCollapse: "collapse",
    borderTop: "1px dashed black",
    borderBottom: "1px dashed black",
    padding: "3px",
  },
  borderTopDashed: {
    borderCollapse: "collapse",
    borderTop: "1px dashed black",

    padding: "3px",
  },
};

export const ITEM_UOM = [
  { value: "nos", text: "NUMBERS (Nos)" },
  { value: "pcs", text: "PIECES (Pcs)" },
  { value: "gm", text: "GRAMMES (Gm)" },
  { value: "kg", text: "KILOGRAMS (Kg)" },
  { value: "ml", text: "MILILITRE (Ml)" },
  { value: "ltr", text: "LITRE (Ltr)" },
  { value: "mtr", text: "METERS (Mtr)" },
  { value: "sqf", text: "SQUARE FEET (Sqf)" },
  { value: "sqm", text: "SQUARE METERS (Sqm)" },
];
